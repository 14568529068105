var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrap_row"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"form_data"},[_c('div',{staticClass:"row",class:[
						_vm.companyInfoValidClass.companyName == 'error' || _vm.companyInfoValidClass.companyName == 'empty'
							? 'error'
							: _vm.companyInfoValidClass.companyName == 'valid'
							? 'valid'
							: '',
					]},[_c('div',{staticClass:"com_ipt_floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.companyName),expression:"companyInfo.companyName"}],staticClass:"asterisk",attrs:{"type":"text","placeholder":"."},domProps:{"value":(_vm.companyInfo.companyName)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyInfo, "companyName", $event.target.value)},function($event){[
									_vm.checkInputLength($event, 2, 64)
										? (_vm.companyInfoValidClass.companyName = 'valid')
										: (_vm.companyInfoValidClass.companyName = 'error'),
								]}]}}),_c('label',{attrs:{"for":"target"}},[_vm._v("회사명")])]),(_vm.companyInfoValidClass.companyName == 'error')?_c('span',{staticClass:"sys_msg"},[_vm._v("회사명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyInfoValidClass.companyName == 'empty')?_c('span',{staticClass:"sys_msg"},[_vm._v("회사명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"row",class:[
						_vm.companyInfoValidClass.employeeNum == 'error' || _vm.companyInfoValidClass.employeeNum == 'empty'
							? 'error'
							: _vm.companyInfoValidClass.employeeNum == 'valid'
							? 'valid'
							: '',
					]},[_c('div',{staticClass:"com_ipt_floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyInfo.employeeNum),expression:"companyInfo.employeeNum"},{name:"doms-inputValidate",rawName:"v-doms-inputValidate:onlyNumber",arg:"onlyNumber"}],staticClass:"asterisk",attrs:{"min":"0","placeholder":".","id":"target"},domProps:{"value":(_vm.companyInfo.employeeNum)},on:{"keyup":function($event){[
									parseInt(_vm.companyInfo.employeeNum.length) < 1 || _vm.companyInfo.employeeNum <= 0
										? (_vm.companyInfoValidClass.employeeNum = 'error')
										: (_vm.companyInfoValidClass.employeeNum = 'valid'),
								]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyInfo, "employeeNum", $event.target.value)}}}),_c('label',{attrs:{"for":"target"}},[_vm._v("직원수")])]),(_vm.companyInfoValidClass.employeeNum == 'error')?_c('span',{staticClass:"sys_msg"},[_vm._v("직원수를 입력해주세요. (0이상의 숫자)")]):_vm._e(),(_vm.companyInfoValidClass.employeeNum == 'empty')?_c('span',{staticClass:"sys_msg"},[_vm._v("직원수는 필수 입력 항목입니다.")]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form_tit"},[_c('span',{staticClass:"txt_large"},[_vm._v("회사 정보")])])
}]

export { render, staticRenderFns }