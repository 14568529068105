var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap_row"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"form_data"},[_c('div',{staticClass:"row",class:[
					_vm.companyManagerInfoValidClass.name == 'error' || _vm.companyManagerInfoValidClass.name == 'empty'
						? 'error'
						: _vm.companyManagerInfoValidClass.name == 'valid'
						? 'valid'
						: '',
				]},[_c('div',{staticClass:"com_ipt_floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.name),expression:"companyManagerInfo.name"}],staticClass:"asterisk",attrs:{"type":"text","placeholder":"."},domProps:{"value":(_vm.companyManagerInfo.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "name", $event.target.value)},function($event){[
								_vm.checkInputLength($event, 2, 64)
									? (_vm.companyManagerInfoValidClass.name = 'valid')
									: (_vm.companyManagerInfoValidClass.name = 'error'),
							]}]}}),_c('label',{attrs:{"for":"target"}},[_vm._v("담당자명")])]),(_vm.companyManagerInfoValidClass.name == 'error')?_c('span',{staticClass:"sys_msg"},[_vm._v("담당자명은 2자 이상, 64자 이하를 입력해야 합니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.name == 'empty')?_c('span',{staticClass:"sys_msg"},[_vm._v("담당자명은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"row",class:[
					_vm.companyManagerInfoValidClass.email == 'error' || _vm.companyManagerInfoValidClass.email == 'empty'
						? 'error'
						: _vm.companyManagerInfoValidClass.email == 'valid'
						? 'valid'
						: '',
				]},[_c('div',{staticClass:"com_ipt_floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.email),expression:"companyManagerInfo.email"}],staticClass:"asterisk",attrs:{"type":"text","placeholder":"."},domProps:{"value":(_vm.companyManagerInfo.email)},on:{"keyup":function($event){[
								_vm.checkEmail(_vm.companyManagerInfo.email)
									? (_vm.companyManagerInfoValidClass.email = 'valid')
									: (_vm.companyManagerInfoValidClass.email = 'error'),
							]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "email", $event.target.value)}}}),_c('label',{attrs:{"for":"target"}},[_vm._v("이메일")])]),(_vm.companyManagerInfoValidClass.email == 'error')?_c('span',{staticClass:"sys_msg"},[_vm._v("이메일 형식이 올바르지 않습니다. ")]):_vm._e(),(_vm.companyManagerInfoValidClass.email == 'empty')?_c('span',{staticClass:"sys_msg"},[_vm._v("담당자 이메일은 필수 입력 항목입니다. ")]):_vm._e()]),_c('div',{staticClass:"row",class:[
					_vm.companyManagerInfoValidClass.phone == 'error' || _vm.companyManagerInfoValidClass.phone == 'empty'
						? 'error'
						: _vm.companyManagerInfoValidClass.phone == 'valid'
						? 'valid'
						: '',
				]},[_c('div',{staticClass:"com_ipt_floating"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyManagerInfo.phone),expression:"companyManagerInfo.phone"}],staticClass:"asterisk",attrs:{"type":"text","placeholder":".","value":"01099990000"},domProps:{"value":(_vm.companyManagerInfo.phone)},on:{"keyup":function($event){[
								_vm.checkPhone(_vm.companyManagerInfo.phone)
									? (_vm.companyManagerInfoValidClass.phone = 'valid')
									: (_vm.companyManagerInfoValidClass.phone = 'error'),
							]},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.companyManagerInfo, "phone", $event.target.value)}}}),_c('label',{attrs:{"for":"target"}},[_vm._v("휴대전화")])]),(_vm.companyManagerInfoValidClass.phone == 'error')?_c('span',{staticClass:"sys_msg"},[_vm._v("번호형식이 올바르지 않습니다. (-, +, 0~9)")]):_vm._e(),(_vm.companyManagerInfoValidClass.phone == 'empty')?_c('span',{staticClass:"sys_msg"},[_vm._v("담당자 휴대전화는 필수 입력 항목입니다.")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form_tit"},[_c('span',{staticClass:"txt_large"},[_vm._v("담당자 정보")])])
}]

export { render, staticRenderFns }