<template>
	<div class="wrap_row">
		<div class="row">
			<label class="form_tit">
				<span class="txt_large">담당자 정보</span>
			</label>
			<div class="form_data">
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.name == 'error' || companyManagerInfoValidClass.name == 'empty'
							? 'error'
							: companyManagerInfoValidClass.name == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.name"
							@input="
								[
									checkInputLength($event, 2, 64)
										? (companyManagerInfoValidClass.name = 'valid')
										: (companyManagerInfoValidClass.name = 'error'),
								]
							"
						/>
						<label for="target">담당자명</label>
					</div>
					<span v-if="companyManagerInfoValidClass.name == 'error'" class="sys_msg"
						>담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</span>
					<span v-if="companyManagerInfoValidClass.name == 'empty'" class="sys_msg"
						>담당자명은 필수 입력 항목입니다.
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.email == 'error' || companyManagerInfoValidClass.email == 'empty'
							? 'error'
							: companyManagerInfoValidClass.email == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.email"
							@keyup="
								[
									checkEmail(companyManagerInfo.email)
										? (companyManagerInfoValidClass.email = 'valid')
										: (companyManagerInfoValidClass.email = 'error'),
								]
							"
						/>
						<label for="target">이메일</label>
					</div>
					<span v-if="companyManagerInfoValidClass.email == 'error'" class="sys_msg"
						>이메일 형식이 올바르지 않습니다.
					</span>
					<span v-if="companyManagerInfoValidClass.email == 'empty'" class="sys_msg"
						>담당자 이메일은 필수 입력 항목입니다.
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.phone == 'error' || companyManagerInfoValidClass.phone == 'empty'
							? 'error'
							: companyManagerInfoValidClass.phone == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							value="01099990000"
							v-model="companyManagerInfo.phone"
							@keyup="
								[
									checkPhone(companyManagerInfo.phone)
										? (companyManagerInfoValidClass.phone = 'valid')
										: (companyManagerInfoValidClass.phone = 'error'),
								]
							"
						/>
						<label for="target">휴대전화</label>
					</div>
					<span v-if="companyManagerInfoValidClass.phone == 'error'" class="sys_msg"
						>번호형식이 올바르지 않습니다. (-, +, 0~9)</span
					>
					<span v-if="companyManagerInfoValidClass.phone == 'empty'" class="sys_msg"
						>담당자 휴대전화는 필수 입력 항목입니다.</span
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	data() {
		const origin = this.$store.getters.getEstimateAllCompanyInfo.companyManagerInfo;
		return {
			companyManagerInfo: {
				name: origin.name ? origin.name : '',
				email: origin.email ? origin.email : '',
				phone: origin.phone ? origin.phone : '',
			},
			companyManagerInfoValidClass: {
				name: origin.name ? 'valid' : '',
				email: origin.email ? 'valid' : '',
				phone: origin.phone ? 'valid' : '',
			},
		};
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyManagerInfo)) {
				const key = Object.keys(this.companyManagerInfo)[idx];
				const value = this.companyManagerInfo[key];

				if (!value || value.length == 0) {
					this.companyManagerInfoValidClass[key] = 'empty';
					isAllValid = false;
				}
				if (this.companyManagerInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyManagerInfo',
				companyManagerInfo: this.companyManagerInfo,
				companyManagerInfoValidClass: this.companyManagerInfoValidClass,
			});
		},
	},
};
</script>

<style></style>
