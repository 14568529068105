<template>
	<div>
		<div class="wrap_row">
			<div class="row">
				<label class="form_tit">
					<span class="txt_large">회사 정보</span>
				</label>
				<div class="form_data">
					<div
						class="row"
						:class="[
							companyInfoValidClass.companyName == 'error' || companyInfoValidClass.companyName == 'empty'
								? 'error'
								: companyInfoValidClass.companyName == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.companyName = 'valid')
											: (companyInfoValidClass.companyName = 'error'),
									]
								"
								v-model="companyInfo.companyName"
							/>
							<label for="target">회사명</label>
						</div>

						<span v-if="companyInfoValidClass.companyName == 'error'" class="sys_msg"
							>회사명은 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
						<span v-if="companyInfoValidClass.companyName == 'empty'" class="sys_msg"
							>회사명은 필수 입력 항목입니다.
						</span>
					</div>

					<div
						class="row"
						:class="[
							companyInfoValidClass.employeeNum == 'error' || companyInfoValidClass.employeeNum == 'empty'
								? 'error'
								: companyInfoValidClass.employeeNum == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal / button-->
						<!--button layout-->
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								min="0"
								placeholder="."
								id="target"
								v-model="companyInfo.employeeNum"
								v-doms-inputValidate:onlyNumber=""
								@keyup="
									[
										parseInt(companyInfo.employeeNum.length) < 1 || companyInfo.employeeNum <= 0
											? (companyInfoValidClass.employeeNum = 'error')
											: (companyInfoValidClass.employeeNum = 'valid'),
									]
								"
							/>
							<label for="target">직원수</label>
						</div>
						<span v-if="companyInfoValidClass.employeeNum == 'error'" class="sys_msg"
							>직원수를 입력해주세요. (0이상의 숫자)</span
						>
						<span v-if="companyInfoValidClass.employeeNum == 'empty'" class="sys_msg"
							>직원수는 필수 입력 항목입니다.</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	data() {
		const origin = this.$store.getters.getEstimateAllCompanyInfo.companyInfo;
		console.log(origin);
		return {
			companyInfo: {
				companyName: origin.companyName ? origin.companyName : '',
				employeeNum: origin.employeeNum ? origin.employeeNum : '',
			},
			companyInfoValidClass: {
				companyName: origin.employeeNum ? 'valid' : '',
				employeeNum: origin.employeeNum ? 'valid' : '',
			},
		};
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		'companyInfo.employeeNum'() {
			if (isNaN(this.companyInfo.employeeNum)) {
				this.companyInfo.employeeNum = '';
			}
		},
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				this.sendValidInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];

				if (!value || value.length == 0) {
					this.companyInfoValidClass[key] = 'empty';
					isAllValid = false;
				}
				if (this.companyInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyInfo',
				companyInfo: this.companyInfo,
				companyInfoValidClass: this.companyInfoValidClass,
			});
		},
	},
};
</script>

<style></style>
